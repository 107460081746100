body.app-body.flavour-glitch > #mastodon .compose-form__autosuggest-wrapper > :last-child {
  padding-bottom: 2em !important;
}
body.app-body.flavour-glitch > #mastodon .compose-form__buttons {
  width: 100%;
}
body.app-body.flavour-glitch > #mastodon .compose-form__buttons > div:last-child {
  margin-inline-start: auto;
}
body.app-body.flavour-glitch > #mastodon .compose-form__buttons-wrapper {
  position: relative;
}
body.app-body.flavour-glitch > #mastodon .character-counter__wrapper {
  position: absolute;
  inset-inline-end: 0;
  bottom: 100%;
  margin: 4px 4px;
  border-radius: 6px;
  padding: 0.1em 0.5em;
}
body.app-body.flavour-glitch > #mastodon .character-counter__wrapper span {
  font-size: 0.9em;
  font-weight: 500;
}
body.app-body.flavour-glitch > #mastodon .compose-form__highlightable {
  margin-bottom: 50px !important;
}
body.app-body.flavour-glitch > #mastodon .compose-form__submit {
  position: absolute;
  right: -12px;
  top: 100%;
  margin-top: 24px;
}
body.app-body.flavour-glitch > #mastodon .compose-form__submit button {
  padding: 10px 20px;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content {
  height: auto !important;
  overflow: visible;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content .status__content__text {
  mask: linear-gradient(to bottom, #000 50px, transparent) !important;
  -webkit-mask: linear-gradient(to bottom, #000 50px, transparent) !important;
  max-height: 100px;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content p:not(:last-child) {
  margin-bottom: 0.4em;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content br {
  display: block;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content::after {
  content: unset;
}
body.app-body.flavour-glitch > #mastodon .collapsed.muted .status__content__text ~ * {
  display: none;
}
body.app-body.flavour-glitch > #mastodon .status:not(.status-direct) > .status__content {
  margin-block: -90px -100px !important;
  padding-block: 100px !important;
}
body.app-body.flavour-glitch > #mastodon .status:not(.status-direct) > .status__content .status__content__text {
  margin-top: 0px;
}
body.app-body.flavour-glitch > #mastodon .status:not(.status-direct) > .status__content > :last-child:not(.status__content__text) {
  margin-bottom: 5px !important;
}
body.app-body.flavour-glitch > #mastodon .status .full-width {
  margin-inline: 0 !important;
}
body.app-body.flavour-glitch > #mastodon .status .status__action-bar {
  position: static;
}
body.app-body.flavour-glitch > #mastodon .status__info .notification__message {
  padding-top: 0 !important;
  padding-inline-start: 0 !important;
}
body.app-body.flavour-glitch > #mastodon .status__display-name {
  line-height: 22px;
}
body.app-body.flavour-glitch > #mastodon .display-name__account {
  font-size: 15px;
}
body.app-body.flavour-glitch > #mastodon .media-gallery__item > .media-gallery__preview {
  display: unset;
}
body.app-body.flavour-glitch > #mastodon .status__action-bar-spacer {
  min-width: 5px;
}
body.app-body.flavour-glitch > #mastodon .status__relative-time {
  margin-inline: auto 5px !important;
  z-index: 2;
  flex-grow: 0 !important;
  min-width: 5ch !important;
  max-width: unset !important;
}
body.app-body.flavour-glitch > #mastodon .status__relative-time time {
  display: inline !important;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar {
  width: unset;
  margin-top: 8px;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar button {
  border-radius: 6px !important;
  padding-block: 2px;
  border: 1px solid var(--border-color-2);
}
body.app-body.flavour-glitch > #mastodon .reactions-bar button .reactions-bar__item__emoji {
  height: 2em;
  width: 2em;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar button:not(.active) {
  background: none;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar:empty {
  display: none;
}
body.app-body.flavour-glitch > #mastodon .notification__message + .status {
  padding-top: 0 !important;
}
body.app-body.flavour-glitch > #mastodon .notification > .notification__message {
  padding-inline: 15px !important;
  padding-top: 18px !important;
}
body.app-body.flavour-glitch > #mastodon .notification__favourite-icon-wrapper {
  position: static;
  margin-inline-end: 10px;
}
body.app-body.flavour-glitch > #mastodon .notification__favourite-icon-wrapper i {
  width: 1.28571429em !important;
  text-align: center;
}
body.app-body.flavour-glitch > #mastodon .detailed-status__wrapper .focusable:not(.status)::before {
  content: unset !important;
}
body.app-body.flavour-glitch > #mastodon .setting-text {
  border-radius: 0 !important;
  margin: 4px;
  width: calc(100% - 8px);
}
body.app-body.flavour-glitch > #mastodon .column-settings__pillbar {
  border-radius: var(--radius);
}
body.app-body.flavour-glitch > #mastodon .pillbar-button {
  border-radius: 0 !important;
  padding: 6px;
}
body.app-body.flavour-glitch > #mastodon .account__header__account-note:focus-within {
  border-radius: var(--radius) !important;
}
body.app-body.flavour-glitch > #mastodon .account__header__account-note__header {
  align-items: center;
}
body.app-body.flavour-glitch > #mastodon .account__header__account-note__header button {
  display: flex;
  gap: 0.2em;
}
body.app-body.flavour-glitch > #mastodon .account__header__account-note__content {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}
body.app-body.flavour-glitch > #mastodon .account-card .media-modal__close {
  left: 10px;
  top: 10px;
}
body.app-body.flavour-glitch > #mastodon .account-card .media-modal__close::before {
  content: "";
  position: absolute;
  inset: -60px -30px;
  background: linear-gradient(to right, #000, transparent);
  transform: rotate(45deg);
  z-index: -1;
  opacity: 0.5;
}
.layout-multiple-columns.flavour-glitch .drawer {
  flex-grow: 0.2;
}
.layout-multiple-columns.flavour-glitch .drawer__inner {
  margin-top: -20px;
  max-height: unset !important;
  min-height: calc(100% + 40px);
}